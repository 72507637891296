/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
*/

import { useGLTF } from "@react-three/drei";
import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { ContextConfigParams, GLTFResult, Styles } from 'src/sections/Mouth/types';

interface ModelProps {
    setIsClickable: Dispatch<SetStateAction<boolean>>;
}

export const Model = ({
    config,
    setConfig,
    setIsClickable,
    ...props
}: ContextConfigParams & ModelProps & JSX.IntrinsicElements["group"]) => {
    const [teethSelected, setTeethSelected] = useState<number[]>([]);

    useEffect(() => {
        setTeethSelected(config.teethSelected)
    }, [config.teethSelected])

    useEffect(() => {
        setConfig({
            ...config,
            teethSelected
        })
    }, [teethSelected])

    const { nodes, materials } = useGLTF("/mouth.gltf") as unknown as GLTFResult;

    const teeth = [
        {
            id: 11,
            outer: nodes.tooth_11.geometry,
            inner: nodes.tooth_11_inner.geometry,
        },
        {
            id: 12,
            outer: nodes.tooth_12.geometry,
            inner: nodes.tooth_12_inner.geometry
        },
        {
            id: 13,
            outer: nodes.tooth_13.geometry,
            inner: nodes.tooth_13_inner.geometry
        },
        {
            id: 14,
            outer: nodes.tooth_14.geometry,
            inner: nodes.tooth_14_inner.geometry
        },
        {
            id: 15,
            outer: nodes.tooth_15.geometry,
        },
        {
            id: 16,
            outer: nodes.tooth_16.geometry,
        },
        {
            id: 17,
            outer: nodes.tooth_17.geometry,
        },
        {
            id: 21,
            outer: nodes.tooth_21.geometry,
            inner: nodes.tooth_21_inner.geometry
        },
        {
            id: 22,
            outer: nodes.tooth_22.geometry,
            inner: nodes.tooth_22_inner.geometry
        },
        {
            id: 23,
            outer: nodes.tooth_23.geometry,
            inner: nodes.tooth_23_inner.geometry
        },
        {

            id: 24,
            outer: nodes.tooth_24.geometry,
            inner: nodes.tooth_24_inner.geometry
        },
        {
            id: 25,
            outer: nodes.tooth_25.geometry,
        },
        {
            id: 26,
            outer: nodes.tooth_26.geometry,
        },
        {
            id: 27,
            outer: nodes.tooth_27.geometry,
        },
        {
            id: 31,
            outer: nodes.tooth_31.geometry,
            inner: nodes.tooth_31_inner.geometry
        },
        {
            id: 32,
            outer: nodes.tooth_32.geometry,
            inner: nodes.tooth_32_inner.geometry
        },
        {
            id: 33,
            outer: nodes.tooth_33.geometry,
            inner: nodes.tooth_33_inner.geometry
        },
        {
            id: 34,
            outer: nodes.tooth_34.geometry,
            inner: nodes.tooth_34_inner.geometry
        },
        {
            id: 35,
            outer: nodes.tooth_35.geometry,
            inner: nodes.tooth_35_inner.geometry
        },
        {
            id: 36,
            outer: nodes.tooth_36.geometry,
        },
        {
            id: 37,
            outer: nodes.tooth_37.geometry,
        },
        {
            id: 41,
            outer: nodes.tooth_41.geometry,
            inner: nodes.tooth_41_inner.geometry
        },
        {
            id: 42,
            outer: nodes.tooth_42.geometry,
            inner: nodes.tooth_42_inner.geometry
        },
        {
            id: 43,
            outer: nodes.tooth_43.geometry,
            inner: nodes.tooth_43_inner.geometry
        },
        {
            id: 44,
            outer: nodes.tooth_44.geometry,
            inner: nodes.tooth_44_inner.geometry
        },
        {
            id: 45,
            outer: nodes.tooth_45.geometry,
            inner: nodes.tooth_45_inner.geometry
        },
        {
            id: 46,
            outer: nodes.tooth_46.geometry,
        },
        {
            id: 47,
            outer: nodes.tooth_47.geometry,
        }
    ]

    return (
        <group {...props}>
            {teeth.map(tooth => {
                const isSelected = teethSelected.includes(tooth.id);
                const materialSelected = config?.texture ? materials[config.texture] : materials.selected;
                const material = isSelected ? materialSelected : materials.white;
                const innerMaterial = config?.style === Styles.window ? materials.white : material

                return (
                    <group
                        key={`tooth_${tooth.id}`}
                    // onPointerEnter={() => { setIsClickable(true); }}
                    // onPointerLeave={() => { setIsClickable(false); }}
                    // onClick={() => {
                    //     const newTeethSelected = [...teethSelected];

                    //     if (newTeethSelected.includes(tooth.id)) {
                    //         const indexToRemove = newTeethSelected.findIndex(i => i === tooth.id)
                    //         newTeethSelected.splice(indexToRemove, 1);
                    //     } else {
                    //         newTeethSelected.push(tooth.id);
                    //     }

                    //     setTeethSelected(newTeethSelected);
                    // }}
                    >
                        <mesh
                            castShadow
                            receiveShadow
                            geometry={tooth.outer}
                            material={material}
                        />

                        {tooth.inner && (
                            <mesh
                                castShadow
                                receiveShadow
                                geometry={tooth.inner}
                                material={innerMaterial}
                            />
                        )}


                    </group>
                );
            })}

            <mesh
                castShadow
                receiveShadow
                geometry={nodes.jaw_lower.geometry}
                material={materials.gum}
            />
            <mesh
                castShadow
                receiveShadow
                geometry={nodes.jaw_upper.geometry}
                material={materials.gum}
            />
        </group>
    );
}

useGLTF.preload("/mouth.gltf");
