import { Dispatch, SetStateAction } from 'react';
import * as THREE from "three";
import { GLTF } from "three-stdlib";

export type GLTFResult = GLTF & {
	nodes: GLTFNodes;
	materials: GLTFMaterials;
};

interface GLTFNodes {
	tooth_21: THREE.Mesh;
	tooth_11: THREE.Mesh;
	tooth_12: THREE.Mesh;
	tooth_13: THREE.Mesh;
	tooth_14: THREE.Mesh;
	tooth_15: THREE.Mesh;
	tooth_16: THREE.Mesh;
	tooth_17: THREE.Mesh;
	tooth_22: THREE.Mesh;
	tooth_23: THREE.Mesh;
	tooth_24: THREE.Mesh;
	tooth_25: THREE.Mesh;
	tooth_26: THREE.Mesh;
	tooth_27: THREE.Mesh;
	tooth_37: THREE.Mesh;
	tooth_36: THREE.Mesh;
	tooth_35: THREE.Mesh;
	tooth_34: THREE.Mesh;
	tooth_33: THREE.Mesh;
	tooth_32: THREE.Mesh;
	tooth_31: THREE.Mesh;
	tooth_41: THREE.Mesh;
	tooth_42: THREE.Mesh;
	tooth_43: THREE.Mesh;
	tooth_44: THREE.Mesh;
	tooth_45: THREE.Mesh;
	tooth_46: THREE.Mesh;
	tooth_47: THREE.Mesh;
	jaw_lower: THREE.Mesh;
	jaw_upper: THREE.Mesh;
	tooth_21_inner: THREE.Mesh;
	tooth_22_inner: THREE.Mesh;
	tooth_23_inner: THREE.Mesh;
	tooth_24_inner: THREE.Mesh;
	tooth_11_inner: THREE.Mesh;
	tooth_12_inner: THREE.Mesh;
	tooth_13_inner: THREE.Mesh;
	tooth_14_inner: THREE.Mesh;
	tooth_41_inner: THREE.Mesh;
	tooth_42_inner: THREE.Mesh;
	tooth_43_inner: THREE.Mesh;
	tooth_44_inner: THREE.Mesh;
	tooth_45_inner: THREE.Mesh;
	tooth_31_inner: THREE.Mesh;
	tooth_32_inner: THREE.Mesh;
	tooth_33_inner: THREE.Mesh;
	tooth_34_inner: THREE.Mesh;
	tooth_35_inner: THREE.Mesh;
}

interface GLTFMaterials {
	chrome: THREE.MeshStandardMaterial;
	white: THREE.MeshStandardMaterial;
	gold_white: THREE.MeshStandardMaterial;
	gold_pink: THREE.MeshStandardMaterial;
	gold: THREE.MeshStandardMaterial;
	gum: THREE.MeshStandardMaterial;
	selected: THREE.MeshStandardMaterial;
	diamond: THREE.MeshStandardMaterial;
}

export interface ContextConfigParams {
	config: Config;
	setConfig: Dispatch<SetStateAction<Config>>;
}

export interface Config {
	teethSelected: number[];
	texture?: Textures;
	style?: Styles;
}

export enum Textures {
	white = 'white',
	gold = 'gold',
	gold_pink = 'gold_pink',
	gold_white = 'gold_white',
	chrome = 'chrome',
	selected = 'selected',
	diamond = 'diamond'
}

export enum Styles {
	smooth = 'smooth',
	beveled = 'beveled',
	window = 'window'
}
